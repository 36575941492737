@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: 'FKRasterGrotesk-Smooth';
    src: url('/FKRasterGrotesk-Smooth.woff2') format('woff2'),
        url('/FKRasterGrotesk-Smooth.woff') format('woff'),
        url('/FKRasterGrotesk-Smooth.otf') format('otf');
}

@font-face {
    font-family: 'NeoSansPro';
    src: url(../public/NeoSansProRegular.ttf) format('truetype');
}

@font-face {
    font-family: 'NeoSansProBold';
    src: url(../public/NeoSansProBold.ttf) format('truetype');
}

.login-button {
    background-color: #2c3539;
    color: white;
    font-size: 16px;
    padding: 16px 30px;
    margin-top: 10%;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    text-align: center;
}

.login-button:hover {
    background-color: #232a2e;
}

.add-button {
    display: flex;
    align-items: center;
    gap: 8px;
    border: 1.25px solid #ff7d40;
    padding: 6px 12px;
    background: #ffffff;
    border-radius: 2px;
    color: #1c2127;
    font-weight: 500;
}

.add-button:hover {
    background-color: #ffe6d9;
}

.add-button svg {
    color: #ff7d40;
}

tr.archived td,
h2.archived {
    color: lightgrey;
    font-style: italic;
}

li.routine-exercise-card {
    border: 2px solid #1c2225;
    border-radius: 10px;
    margin-top: 20px;
    background-color: white;
}

.routine-exercise-title {
    font-family: NeoSansProBold;
    font-size: 20px;
    kerning: -2%;
    padding-bottom: 8px;
    width: 300px;
}

.routine-exercise-card .icons,
.routine-exercise-circuit-card .icons {
    display: flex;
    gap: 10px;
    justify-content: center;
    flex-grow: 1;
    flex-wrap: wrap;
}

.routine-set-header {
    color: #999897;
    font-weight: 500;
    font-size: medium;
}

.drag-handle:hover {
    background-color: rgba(143, 153, 168, 0.15);
}

li.routine-exercise-card > div {
    padding: 24px 16px;
    display: flex;
    gap: 10px;
    align-items: center;
}

li.routine-exercise-circuit-card > div {
    padding: 10px;
    display: flex;
    gap: 10px;
    align-items: center;
}

li.routine-exercise-circuit-card {
    border-bottom: 2px solid #dcdcdc;
}
li.routine-exercise-circuit-card:last-child {
    border-bottom: 0px solid #ffffff;
}

li.exercise-circuit-card {
    /* box-shadow: 0px 2px 20px 2px #dbdbdb; */
    border: 2px solid #1c2225;
    border-radius: 10px;
    margin-top: 20px;
    background-color: white;
}

a.unstyle {
    color: inherit;
    text-decoration: inherit;
    cursor: inherit;
}

.program-grid {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    width: 100%;
    overflow-x: auto;
    overflow-y: visible;
    scroll-behavior: smooth;
}

.program-day {
    display: flex;
    gap: 10px;
    flex-direction: column;
    min-height: 90px;
    align-items: stretch;
    padding: 6px 4px;
    border: 2px solid transparent;
    background: white;
    border-radius: 4px;
}

.program-day:hover,
.program-routine:hover {
    cursor: pointer;
}

.program-day:hover {
    border: 2px solid #ff7d40;
}

.program-rest-day {
    color: #595959;
}

.program-expanded-exercise-table th {
    font-weight: normal;
    text-align: left;
    color: #999897;
}

strong {
    font-weight: 600;
}

tr.hover-row:hover {
    background-color: #cfcfcfb0;
}
